import { template as template_8490f05366ac474c8d6e633c0f4cccc1 } from "@ember/template-compiler";
const FKText = template_8490f05366ac474c8d6e633c0f4cccc1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
